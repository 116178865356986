.form-control::placeholder {
  font-style: italic!important;
  opacity: 0.3!important;
}

body {
  font-family: 'Inter', serif !important;
}

.cant-select {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.zoom {
  transition: all 0.3s ease; /* Aggiungi una transizione per un effetto più fluido */
}

.zoom:hover {
  transform: scale(1.1); /* Rendi il testo più grande del 10% */
}

.grey{
  color: #858585!important;
}

.yellow {
  color: #FEFC54!important;
}

.purple {
  color: #8455EA!important;
}

.lightgreen{
  color:#55D6BE!important;
}

.inter {
  font-family: 'Inter', sans-serif;
}

.text-shadow {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.font10 {
  font-size: 10px
}

.font12 {
  font-size: 12px
}

.font14 {
  font-size: 14px
}

.font15 {
  font-size: 15px
}

.font20 {
  font-size: 20px
}

.font22 {
  font-size: 22px
}


.font25 {
  font-size: 25px
}

.font30{
  font-size: 30px
}

.font32 {
  font-size: 32px
}

.font35 {
  font-size: 35px
}

.font36 {
  font-size: 36px
}

.font40 {
  font-size: 40px
}

.font45 {
  font-size: 45px
}

.font50{
  font-size: 50px
}

.font55 {
  font-size: 55px
}

.font65 {
  font-size: 65px
}

.font75 {
  font-size: 75px
}

.font85 {
  font-size: 85px
}

.font125{
  font-size: 125px
}

@media screen and (max-width: 1400px) {
.font30{
  font-size: 20px
}

  .font32 {
    font-size: 20px
  }

  .font35 {
  font-size: 20px
}

.font45 {
  font-size: 25px
}

.font55 {
  font-size: 25px
}

.font50{
  font-size: 30px
}

.font65 {
  font-size: 35px
}

.font85 {
  font-size: 30px
}

.font125{
  font-size: 75px
}
}
