.lightgreen-bg {
  background: #55D6BE
}

.yellow-bg {
  background: #FEFC54
}

.purple-bg {
  background: #8455EA
}
