input,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: white!important;
  -webkit-text-fill-color: #000 !important;
}

.pday-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  line-height: 1.7;
}

/* Nascondi il checkbox predefinito */
.pday-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Stile del falso elemento checkbox */
.checkmark {
  position: absolute;
  top: 0.4rem;
  left: 0.4rem;
  height: 15px;
  width: 15px;
  background-color: transparent;
  border: 2px solid yellow; /* Colore del bordo giallo */
}

/* Stile del check */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Visualizza il check quando il checkbox è selezionato */
.pday-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Stile del check */
.pday-checkbox .checkmark:after {
  width: 6px;
  height: 12px;
  top: -0.15rem;
  left: 0.20rem;
  border: solid yellow; /* Colore del check viola */
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.box {
  border-radius: 2.5rem;
  padding: 1.8rem !important;
}

.box-pc {
  border-radius: 2.5rem;
}


.custom-flex {
  flex-wrap: nowrap;
  display: flex;
}

@media screen and (max-width: 1200px) {
  .custom-flex {
    flex-direction: column;
  }
}
