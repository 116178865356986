.form-check-input[type="checkbox"] {
  background-color: #c5b4f3 !important;
  color: #8455EA!important;
  border-radius: 2px!important;
  padding:0!important;
  margin:0!important;
  border: 0.1rem solid #8455EA!important
}

.form-check-input[type="checkbox"]:active {
  background-color: #c5b4f3 !important;
  color: #8455EA!important;
  border-radius: 2px!important;
  border: 0.1rem solid #8455EA!important
}

.form-check-input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.dropdown-item:active {
  background: #8455EA !important;
  color: white !important;

  .ph-duotone {
    color: white !important;
  }
}

.default-label {
  min-width: 13rem !important;
  cursor: pointer;
  padding: 0.8rem;
  white-space: nowrap;
  background-color: #c3ffd4 !important;
  color: #23C25D !important;
  font-weight: 600;
  justify-content: center;
  border-radius: 15px;
  flex-wrap: nowrap;
  display: flex;
}

.btn-primary {
  min-width: 150px;
  padding-left: 2rem;
  padding-right: 2rem;
  background: #8455EA !important;
  color: #FEFC54 !important;
  font-weight: bold;
  height: 3.25rem;
  border-radius: 50px;
  border: 1px solid #8455EA !important;
}

.btn-primary:hover {
  background: #FEFC54 !important;
  color: #8455EA !important;
  border: 1px solid #8455EA !important;
}

.btn-primary:active {
  background: white !important;
}

.btn-outline-primary {
  min-width: 150px;
  padding-left: 2rem;
  padding-right: 2rem;
  background: #FEFC54 !important;
  color: #8455EA !important;
  font-weight: bold;
  height: 3.25rem;
  border-radius: 50px;
  border: 1px solid #FEFC54 !important;
}

.btn-outline-primary:hover {
  background: #8455EA !important;
  color: #FEFC54 !important;
  border: 1px solid #FEFC54 !important;
}

.btn-outline-primary:active {
  background: #8455EA !important;
}

.btn-outline-secondary {
  min-width: 150px;
  padding-left: 2rem;
  padding-right: 2rem;
  background: white !important;
  color: #8455EA !important;
  font-weight: bold;
  height: 3.25rem;
  border-radius: 50px;
  border: 1px solid #8455EA !important;
}

.btn-outline-secondary:hover {
  background: #FEFC54 !important;
  color: #8455EA !important;
  border: 1px solid #8455EA !important;
}

.btn-outline-secondary:active {
  background: #8455EA !important;
}

.pay-label {
  border-radius: 5px;
  height: 40px;
  font-size: 12px;
  width: 95px !important;
  padding: 0.65rem;
  white-space: nowrap;
  cursor: pointer;
  margin: auto;
}

.paid {
  @extend .pay-label;
  color: #23C25D;
  background-color: #d2ffe5;
}

.pending-paid {
  @extend .pay-label;
  color: orange;
  background-color: rgba(255, 165, 0, 0.2);
}

.paidnt {
  @extend .pay-label;
  color: #D00000;
  background-color: #ffcccc;
}
